import { Action } from '@ngrx/store';

export enum FilterMonthActionType {
    SET_MONTHYEAR = 'SET_MONTHYEAR',
}

export class SetFilterMonthYear implements Action {
    readonly type = FilterMonthActionType.SET_MONTHYEAR
    constructor(public payload: any){}
}

export type FilterMonthActions = SetFilterMonthYear
