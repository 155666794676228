import { CartActionTypes, CartActions } from './../actions/cart.action';

export let initialState = [];

export function cartReducer(state = initialState, action: CartActions) {
    switch (action.type) {
        case CartActionTypes.SET_TOTAL:
            return { totalCart: action.payload };
        default:
            return state;
    }
}
