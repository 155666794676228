<!-- Right Sidebar -->

<div class="right-bar" (clickOutside)="hide()" [exclude]="'.right-bar-toggle'">

  <perfect-scrollbar class="h-100">
    <div class="rightbar-title px-3 py-4">
      <a href="javascript:void(0);" class="right-bar-toggle float-right" (click)="hide();">
        <i class="mdi mdi-close noti-icon"></i>
      </a>
      <h5 class="m-0">Settings</h5>
    </div>
    <hr class="mt-0">
  </perfect-scrollbar>
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay"></div>
