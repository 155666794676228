<!-- ========== Left Sidebar Start ========== -->
<ng-template #contentTemplate>
    <!--- Sidemenu -->
    <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
            <ng-container *ngFor="let item of menuItems">
                <li class="menu-title" *ngIf="item.isTitle">{{ item.label }}</li>
                <li *ngIf="!item.isTitle && !item.isLayout">
                    <a *ngIf="hasItems(item)" href="javascript:void(0);" [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
                        <i class="{{item.icon}}" *ngIf="item.icon"></i>
                        <span> {{ item.label }}</span>
                        <span class="badge badge-pill badge-{{item.badge.variant}} float-right" *ngIf="item.badge">{{item.badge.text}}</span>
                    </a>
                    <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
                        <i class="{{ item.icon }}" *ngIf="item.icon"></i>
                        <span> {{ item.label }}</span>
                        <span class="badge badge-pill badge-{{item.badge.variant}} float-right" *ngIf="item.badge">{{item.badge.text}}</span>
                    </a>
                    <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                        <li *ngFor="let subitem of item.subItems">
                            <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref" [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                                {{ subitem.label }}
                            </a>
                        </li>
                    </ul>
                </li>
            </ng-container>
        </ul>
    </div>
</ng-template>
<!-- Sidebar -->
<div class="vertical-menu">
    <perfect-scrollbar [config]="configData">
        <div *ngIf="!isCondensed">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </div>
        <div *ngIf="isCondensed">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </div>
    </perfect-scrollbar>
</div>
<!-- Left Sidebar End -->