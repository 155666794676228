<div id="layout-wrapper">
  <app-horizontaltopbar *ngIf="!blank" ></app-horizontaltopbar>
  <!-- <app-horizontaltopbar ></app-horizontaltopbar> -->

  <div class="main-content">
    <div class="page-content" [ngClass]="blank ? 'mt-0 pt-2' : ''">
    <!-- <div class="page-content"> -->
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-footer></app-footer>
  <app-rightsidebar></app-rightsidebar>
</div>