import { FilterMonthActionType, FilterMonthActions } from './../actions/filtermonth.action';

export let initialState = [];

export function monthYearReducer(state = initialState, action: FilterMonthActions) {
    switch (action.type) {
        case FilterMonthActionType.SET_MONTHYEAR:
            return { monthYear: action.payload };
        default:
            return state;
    }
}
