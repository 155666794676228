import {
    BrowserModule, Title
} from '@angular/platform-browser';
import {
    BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import {
    HttpClientModule
} from '@angular/common/http';
import {
    NgModule
} from '@angular/core';
import {
    initFirebaseBackend
} from './authUtils';
import {
    environment
} from '../environments/environment';
// import {
//     LayoutsModule
// } from './layouts/layouts.module';
import {
    AppRoutingModule
} from './app-routing.module';
import {
    AppComponent
} from './app.component';
import { StoreModule } from '@ngrx/store';
import { AsyncPipe } from '@angular/common';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './core/services/messaging.service';
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateCustomParserFormatter } from "./core/date-formatter";
import { AngularFirestore } from "@angular/fire/firestore";
import { cartReducer } from './core/state/reducers/cart.reducer';
import { monthYearReducer } from './core/state/reducers/filtermonth.reducer';
import { SharedModule } from './shared/shared.module';

initFirebaseBackend(environment.firebaseConfig);
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        StoreModule.forRoot({ cart: cartReducer, monthYear: monthYearReducer }),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        NgProgressModule,
        NgProgressHttpModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        SharedModule
    ],
    // providers: [AsyncPipe],
    providers: [
        MessagingService,
        AsyncPipe,
        AngularFirestore,
        Title,
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }  // <-- add this
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
