import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    NgbDropdownModule,
    NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { MonthDatePickerComponent } from './month-date-picker/month-date-picker.component';


@NgModule({
    declarations: [
        MonthDatePickerComponent,
    ],
    exports: [
        MonthDatePickerComponent,
        NgbDropdownModule,
        NgbModule
    ],
    imports: [
        CommonModule
    ]
})
export class SharedModule { }
