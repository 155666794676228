var arrHost = window.location.hostname.split(".");

var api = "https://api.tanteka.tech";
var img = "https://api.tanteka.tech/assets/img/";
// var img = 'https://storage.googleapis.com/yoayo-image/';
export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyAvCkwVsZ54Z_9jcYNO0usGkUd_XwWrLFU",
    authDomain: "yoayo-mobile-app.firebaseapp.com",
    databaseURL: "https://yoayo-mobile-app.firebaseio.com",
    projectId: "yoayo-mobile-app",
    storageBucket: "yoayo-mobile-app.appspot.com",
    messagingSenderId: "627590926023",
    appId: "1:627590926023:web:fbf731ccf862f2af5138e2",
    measurementId: "G-NS0RTQ1E16",
  },
  // apiURL: 'http://localhost/yoayo/api',
  apiURL: api,
  // imageURL: 'http://localhost/yoayo/api/assets/img/',
  imageURL: img,
};
